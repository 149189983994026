import React from "react"
import styled from "styled-components"

import { VerticalSection, Grid, ValueProp } from "components/common"

import { device, featureGlass, featureColors } from "utils"

const ComplianceAutomated = ({ hat, title, subtitle, media, valueProps }) => {
  const renderGrid = () => (
    <Grid
      col={2}
      gap={32}
      align="center"
      width="380px"
      list={valueProps}
      component={(vp) => <ValueProp orientation="horizontal" {...vp} />}
    />
  )

  return (
    <StyledComplianceAutomated>
      <VerticalSection
        hat={hat}
        title={title}
        titleAs="h2"
        subtitle={subtitle}
        media={media}
        grid={renderGrid}
        mobileFull
      />
    </StyledComplianceAutomated>
  )
}

const StyledComplianceAutomated = styled.div`
  --tg-hat-color: ${featureColors.compliance};

  ${featureGlass};

  @media ${device.laptop} {
    --tg-max-width: 645px;
  }

  .vp_contentWrap {
    @media ${device.laptop} {
      grid-template-columns: 22px auto;
    }
    .image {
      padding-top: 3px;
    }
    .subtitle {
      font-weight: 400;
      line-height: 32px;
    }
  }
`

export default ComplianceAutomated
