import React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Layout, Seo, Nav } from "components/common"
import { useContentfulSection, useMeta } from "utils"

import {
  ComplianceHero,
  ComplianceAutomated,
  ComplianceAuditTrail,
  ComplianceGMP,
  ComplianceLineage,
} from "src/sections/features/compliance"

import ExploreFeatures from "src/sections/features/ExploreFeatures"
import { CtaGetDemo } from "components/cta"

const CompliancePage = ({ data: { compliance } }) => {
  const {
    compliance_hero,
    compliance_automated,
    compliance_audit_trail,
    compliance_gmp,
    compliance_lineage,
    explore_more_features,
    cta_get_demo,
  } = useContentfulSection(compliance.sections)

  const meta = useMeta(compliance)

  return (
    <Layout>
      <Seo {...meta} />
      <Wrap>
        <Nav />
        <ComplianceHero {...compliance_hero} />
        <ComplianceAutomated {...compliance_automated} />
        <ComplianceAuditTrail {...compliance_audit_trail} />
        <ComplianceGMP {...compliance_gmp} />
        <ComplianceLineage {...compliance_lineage} />

        <ExploreFeatures {...explore_more_features} />
        <CtaGetDemo {...cta_get_demo} />
      </Wrap>
    </Layout>
  )
}

const Wrap = styled.div``

export const query = graphql`
  {
    compliance: contentfulPage(pageId: { eq: "compliance" }) {
      ...Page
    }
  }
`

export default CompliancePage
