import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { featureColors } from "utils"

const ComplianceAuditTrail = ({ hat, title, subtitle, media }) => {
  return (
    <StyledComplianceAuditTrail>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        mobileFull
      />
    </StyledComplianceAuditTrail>
  )
}

const StyledComplianceAuditTrail = styled.div`
  --tg-hat-color: ${featureColors.compliance};
`

export default ComplianceAuditTrail
