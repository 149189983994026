import React from "react"
import styled from "styled-components"

import { VerticalSection } from "components/common"

import { featureColors } from "utils"

const ComplianceLineage = ({ hat, title, subtitle, media }) => {
  return (
    <StyledComplianceLineage>
      <VerticalSection
        hat={hat}
        titleAs="h2"
        title={title}
        subtitle={subtitle}
        media={media}
        mobileTgOrder="1"
        mobileFull
      />
    </StyledComplianceLineage>
  )
}

const StyledComplianceLineage = styled.div`
  --tg-hat-color: ${featureColors.compliance};
  --section-padding-bottom: 0;
  --section-mobile-padding-bottom: 0;
`

export default ComplianceLineage
