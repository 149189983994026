import React from "react"
import styled from "styled-components"

import { HSection } from "components/common"

import { device, featureColors } from "utils"

const ComplianceHero = ({ hatIcon, hat, title, subtitle, media }) => {
  return (
    <StyledComplianceHero>
      <HSection
        hatIcon={hatIcon}
        hat={hat}
        title={title}
        titleAs="h1"
        subtitle={subtitle}
        media={media}
        critical
        full
        mobileFull
      />
    </StyledComplianceHero>
  )
}

const StyledComplianceHero = styled.div`
  --section-padding-top: 0;
  --section-padding-bottom: 0;
  --section-mobile-padding-top: var(--sp-mobile-nav);
  --section-mobile-padding-bottom: 0;
  --tg-hat-color: ${featureColors.compliance};
  --section-max-width: 1080px;

  margin-bottom: -112px;
  @media ${device.laptop} {
    --tg-max-width: 550px;
  }

  .horizontal_textSection {
    @media ${device.laptop} {
      margin-bottom: 112px;
    }
  }
`

export default ComplianceHero
